// Generated by Framer (12ecc5e)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["kxBhMaJzl", "GByahBzew", "r6FEWLn9_"];

const serializationHash = "framer-m67lM"

const variantClassNames = {GByahBzew: "framer-v-u9jmoc", kxBhMaJzl: "framer-v-12ixglv", r6FEWLn9_: "framer-v-1895m19"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Phone: "GByahBzew", Squares: "kxBhMaJzl", widescreen: "r6FEWLn9_"}

const getProps = ({background, color, height, icon, id, radius, width, ...props}) => { return {...props, bBRPp_UNA: icon ?? props.bBRPp_UNA ?? "IntersectSquare", eWQyWnrNO: radius ?? props.eWQyWnrNO ?? "0px", Mu7sXxoJH: background ?? props.Mu7sXxoJH ?? "rgb(255, 255, 255)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kxBhMaJzl", ZhIi_AOti: color ?? props.ZhIi_AOti ?? "var(--token-181e673f-09b4-4828-8087-edda310aa66a, rgb(32, 32, 32))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string;icon?: string;background?: string;radius?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ZhIi_AOti, bBRPp_UNA, Mu7sXxoJH, eWQyWnrNO, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kxBhMaJzl", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-12ixglv", className, classNames)} data-framer-name={"Squares"} layoutDependency={layoutDependency} layoutId={"kxBhMaJzl"} ref={ref ?? ref1} style={{backgroundColor: Mu7sXxoJH, borderBottomLeftRadius: radiusForCorner(eWQyWnrNO, 3), borderBottomRightRadius: radiusForCorner(eWQyWnrNO, 2), borderTopLeftRadius: radiusForCorner(eWQyWnrNO, 0), borderTopRightRadius: radiusForCorner(eWQyWnrNO, 1), ...style}} {...addPropertyOverrides({GByahBzew: {"data-framer-name": "Phone"}, r6FEWLn9_: {"data-framer-name": "widescreen"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-184j8qy-container"} layoutDependency={layoutDependency} layoutId={"DdamMbbhS-container"}><Phosphor color={ZhIi_AOti} height={"100%"} iconSearch={"House"} iconSelection={bBRPp_UNA} id={"DdamMbbhS"} layoutId={"DdamMbbhS"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-m67lM.framer-sgiw44, .framer-m67lM .framer-sgiw44 { display: block; }", ".framer-m67lM.framer-12ixglv { height: 958px; overflow: hidden; position: relative; width: 958px; will-change: var(--framer-will-change-override, transform); }", ".framer-m67lM .framer-184j8qy-container { flex: none; height: 82%; left: calc(49.89561586638833% - 81.73277661795407% / 2); position: absolute; top: calc(49.89561586638833% - 81.73277661795407% / 2); width: 82%; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 958
 * @framerIntrinsicWidth 958
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"GByahBzew":{"layout":["fixed","fixed"]},"r6FEWLn9_":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ZhIi_AOti":"color","bBRPp_UNA":"icon","Mu7sXxoJH":"background","eWQyWnrNO":"radius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramernssBE4xi7: React.ComponentType<Props> = withCSS(Component, css, "framer-m67lM") as typeof Component;
export default FramernssBE4xi7;

FramernssBE4xi7.displayName = "graphicalElements_";

FramernssBE4xi7.defaultProps = {height: 958, width: 958};

addPropertyControls(FramernssBE4xi7, {variant: {options: ["kxBhMaJzl", "GByahBzew", "r6FEWLn9_"], optionTitles: ["Squares", "Phone", "widescreen"], title: "Variant", type: ControlType.Enum}, ZhIi_AOti: {defaultValue: "var(--token-181e673f-09b4-4828-8087-edda310aa66a, rgb(32, 32, 32)) /* {\"name\":\"Prime Black\"} */", title: "Color", type: ControlType.Color}, bBRPp_UNA: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "IntersectSquare", description: undefined, hidden: undefined, title: "Icon"}, Mu7sXxoJH: {defaultValue: "rgb(255, 255, 255)", title: "Background", type: ControlType.Color}, eWQyWnrNO: {defaultValue: "0px", title: "Radius", type: ControlType.BorderRadius}} as any)

addFonts(FramernssBE4xi7, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})